import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HomeCarousel = () => {
  const items = [
    { className: "item1", imageUrl: "/images/dr1.jpg" },
    { className: "item2", imageUrl: "/images/dr2.jpg" },
    { className: "item3", imageUrl: "/images/dr3.jpg" },
    { className: "item4", imageUrl: "/images/dr4.jpg" },
    { className: "item5", imageUrl: "/images/dr5.jpg" },
    { className: "item6", imageUrl: "/images/dr6.jpg" },
    { className: "item7", imageUrl: "/images/dr7.jpg" },
    { className: "item8", imageUrl: "/images/dr8.jpg" },
    { className: "item9", imageUrl: "/images/dr9.jpg" },
    { className: "item10", imageUrl: "/images/dr10.jpg" },
    { className: "item11", imageUrl: "/images/dr11.jpg" },
    { className: "item12", imageUrl: "/images/dr12.jpg" },
    { className: "item13", imageUrl: "/images/dr13.jpg" },
  ];

  const responsive = {
    large: {
      // screens larger than 1280px
      breakpoint: { max: 4000, min: 1280 },
      items: 4,
      partialVisibilityGutter: 0,
    },
    medium: {
      // screens between 992px and 1280px
      breakpoint: { max: 1280, min: 992 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    small: {
      // screens between 0px and 992px
      breakpoint: { max: 992, min: 464 },
      items: 2,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={3000}
      infinite={true}
      centerMode={false}
      keyBoardControl={false}
      showDots={false}
      arrows={false}
      containerClass="carousel-container"
      itemClass="carousel-itm"
      sliderClass="carousel-slider"
      transitionDuration={700}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={`carousel-itm ${item.className}`}
          style={{
            height: "300px",
            backgroundSize: "cover",
            backgroundImage: `url(${item.imageUrl})`,
          }}
        >
          {/* Content inside the carousel item */}
        </div>
      ))}
    </Carousel>
  );
};

export default HomeCarousel;
